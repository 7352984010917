@import '../../style/reset.css';
@import '../../style/variables.less';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.App {
  text-align: left;
  // background-color: #F3F4F6;
  margin-top: 60px;
  border-top: 20px solid #F3F4F6;
}

 
p{
   padding: 10px 16px;
}