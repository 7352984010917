@charset "utf-8";

/*css reset*/
body {
  font: 14px/24px "\5FAE\8F6F\96C5\9ED1", "\5b8b\4f53", arial;
  color: #333;
  line-height: 1.4;
}
html, body{
  height: auto;
}
body,
div,
dl,
dd,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
input,
legend,
li,
ol,
ul,
p,
pre,
td,
th,
textarea {
  margin: 0;
  padding: 0
}

button,
input,
select,
textarea {
  outline: none;
  font-family: inherit;
  font-size: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%
}

address,
cite,
code,
em,
i {
  font-style: normal
}

img {
  border: none;
  display: block;
}

ol,
ul {
  list-style: none
}

a {
  color: #2D8DFF;
  text-decoration: none
}

a:hover {
  color: #2fc26e
}

.cf:after {
  content: "";
  display: block;
  height: 0;
  clear: both
}

.cf {
  *zoom: 1
}

.fl {
  float: left
}

.fr {
  float: right
}

.fl,
.fr {
  _display: inline
}


.clearfix::after {
  content: '';
  display: block;
  height: 0;
  clear: both;
}


.container{
  width: 80%;
  margin: auto;
}