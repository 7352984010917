

/**
 * 全局样式变量文件
 */

/**
 * 全局字体-颜色变量定义
 */
 @main-color: #36a092;
 @font-color: #666;// 默认颜色
 @font-color-title: #333;// 主要标题色
 @font-color-subtitle: #999;// 副标题色
 @font-color-success: #67C23A;// 成功字体色
 @font-color-warning: #E6A23C;// 警告字体色
 @font-color-danger: #F56C6C;// 危险字体色
 @font-color-highlight: #409EFF;// 高亮字体色
 @font-color-disabled: #909399;// 禁用字体色
 @border-color-default: #DCDCDC; //分割线默认颜色
 @border-color-second:#EBECEC;

//  公共样式

.dFlex {
  display: flex;
}
.align {
  align-items: center;
}
.justify {
  justify-content: space-between;
}
.around{
  justify-content: space-around;
}
.center {
  justify-content: center;
}
.wrap {
  flex-wrap: wrap;
}
.direaction {
  flex-direction: column;
}
.alignStart {
  align-items: flex-start;
}
.alignCenter {
  align-items: center;
}
.alignend {
  align-items: flex-end;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexRow {
  display: flex;
  flex-flow: row;
}

.flexColumn {
  display: flex;
  flex-flow: column;
}


/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width:10px;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
  border-radius:19px;
  background:#EFF2F4;
  }
